/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

.CategoryFilterOverlay {
    @include desktop {
        background-color: transparent;
        margin-block-end: 20px;
    }

    > .CategoryFilterOverlay-Wrapper {
        background-color: transparent;
    }

    &_isVisible {
        z-index: 101;
    }

    &-Wrapper {
        @include mobile {
            overflow-x: hidden;
        }

        @include mobile {
            grid-template-rows: min-content min-content auto min-content;
        }
    }

    &-Attributes {
        @include desktop {
            padding-block-end: 20px;
        }

        @include mobile {
            padding-block-end: 0;
        }
    }

    &-ResetSection {
        display: flex;
    }

    &-ResetButton {
        @include mobile {
            order: 0;
            display: flex;
            justify-content: flex-end;
            padding: 10px 0;

            .ResetButton-Button {
                width: auto;
            }
        }
    }

    &-ToggleSection {
        @include desktop {
            display: grid;
            grid-column-gap: 48px;
            grid-template-columns: 236px auto auto;
            padding-block: 10px;

            .CategoryFilterOverlay {
                &-ResetButton {
                    display: flex;
                    margin-block-end: 0;
                    margin-inline-end: 30px;
                }
            }
        }

        @include mobile {
            display: grid;
            padding: 16px 16px 81px 16px;
            grid-template-rows: auto min-content min-content;
        }
    }

    &-CigarFinderToggle {
        display: flex;
        justify-content: flex-end;
        align-items: center;

        @include mobile {
            grid-row: 3 / 4;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding-block: 10px;
            flex-wrap: wrap;
        }

        .CategoryPage-ItemsCount {
            font-size: 14px;

            @include desktop {
                margin-inline-end: 20px;
            }

            @include mobile {
                margin: 0;
                padding: 0;
            }
        }
    }

    &-ToggleButton {
        color: var(--body-content-color);

        @include mobile {
            width: auto;
            order: 2;
        }
    }

    &-GroupAttribute {
        display: flex;
        flex-wrap: wrap;
        --option-background-color: transparent;

        .ProductAttributeValue {
            margin-inline-end: 30px;
        }
        
        .ProductAttributeValue-Text {
            padding-block-end: 0;
            margin-inline-end: 0;
        }
    }

    &-CatLink {
        @include mobile {
            grid-row: 2 / 3;
        }

        a {
            display: flex;
            align-items: center;
            color: var(--body-content-color);
            font-weight: normal;

            &:hover {
                text-decoration: underline;
            }

            .ChevronIcon {
                fill: var(--primary-base-color);
                margin-inline-start: 5px;
            }
        }
    }
}
