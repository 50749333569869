/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

.CategoryPage {
    &-Wrapper {
        @include mobile {
            margin-block-start: 0;
        }

        .CategoryDetails {
            grid-column: span 2;
            background-color: transparent;
        }

        .CategoryFilterOverlay {
            @include desktop {
                grid-row: 1 / 2;
                grid-column: 1 / 3;
            }
        }
    }

    &-InfoWrapper {
        .CategoryPage-InfoInnerWrapper {
            padding-block-end: 50px;
            border-bottom: 1px solid #E7E6E5;
        }

        .brand-description-wrapper {
            display: grid;
            grid-template-columns: 5fr 1fr;
            grid-column-gap: 60px;

            .brand-info-link-wrapper {
                .brand-info-link {
                    a {
                        display: flex;
                        align-items: center;
                        justify-content: flex-end;
                        margin-block-start: 15px;

                        span {
                            color: var(--color-black);
                            font-weight: 500;
                        }
                    }
                }
            }
        }
    }
    
    &-PlaceholderWrapper {
        @include desktop {
            grid-row: 1 / 2;
            grid-column: 1 / 3;

            .CategoryPage-PlaceholderList {
                display: grid;
                grid-template-columns: 1fr 1fr 1fr;
                gap: 20px;
                padding: 0;

                .CategoryPage-PlaceholderListItem {
                    min-height: 42px;
                    height: 42px;
                }
            }
        }
    }

    &-BrandsSidebar {
        @include desktop {
            grid-row: 2 / 6;
            grid-column: 1 / 2;
        }
    }

    &-Miscellaneous {
        @include desktop {
            justify-content: flex-start;
            padding-block-end: 10px;
            margin-block-end: 20px;
            border-bottom: 1px solid #E7E6E5;
            grid-column: 3 / 1;

            .CategoryPage-ItemsCount {
                font-size: 14px;
                order: 2;
                margin-inline-start: 20px;
            }
        }
    }

    &-ProductListWrapper {
        @include desktop {
            grid-column: 3 / 1;
        }
    }

    &-LayoutButtons {
        @include desktop {
            flex-direction: row-reverse;
            margin-inline-end: 20px;

            button {
                .GridIcon, .ListIcon {
                    fill: #D9D5D1;

                    &_isActive {
                        fill: #1E1E1E;
                    }
                }
            }
        }
    }
}
