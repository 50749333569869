/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

.CategorySort {
    display: flex;

    &-Option {
        display: flex;
        align-items: center;
        margin-inline-end: 20px;
        font-weight: bold;
        color: #D9D5D1;
        cursor: pointer;
        text-transform: uppercase;

        &:hover {
            color: #1E1E1E;
        }

        &_isActive {
            color: #1E1E1E;

            svg {
                height: 12px;
                width: 12px;
                margin-block-end: 2px;
                margin-inline-start: 3px;
            }

            &:hover {
                svg {
                    fill: var(--primary-base-color);
                    transform: rotate(90deg);
                }
            }

            &.CategorySort-Option_dir_DESC {
                &:hover {
                    svg {
                        transform: rotate(-90deg);
                    }
                }
            }
        }
    }
}
