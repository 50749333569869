/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

.CategoryConfigurableAttributes {
    &-RangeFilters {
        @include desktop {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            grid-template-rows: 1fr 1fr;
            gap: 20px;
        }

        margin-block-end: 20px;
    }

    &-DefaultFilters {
        @include desktop {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr 1fr;
            gap: 20px;
        }

        .ProductConfigurableAttributeDropdown {
            width: 100%;

            .FieldSelect {
                &-Clickable {
                    select {
                        background-color: var(--filter-bg-color);
                        border-color: var(--filter-bg-color);
                        padding: 12px 40px 10px 15px;
                    }

                    svg {
                        fill: var(--black);
                    }
                }
            }
        }

        .ProductConfigurableAttributes {
            &-Expandable {
                position: relative;
                border: 0;

                &.ExpandableContent {
                    @include desktop {
                        /* stylelint-disable-next-line declaration-no-important */
                        border: 0 !important;
                        padding: 0 ;
                    }

                    @include mobile {
                        &:not(:last-of-type) {
                            margin-block-end: 20px;
                        }
                    }
                }

                &ContentButton {
                    background-color: var(--filter-bg-color);
                    padding: 11px 15px;

                    svg {
                        fill: var(--black);
                    }
                }

                &ContentContent {
                    display: none;
                    position: absolute;
                    border: 1px solid var(--primary-divider-color);
                    padding: 10px;
                    width: 100%;
                    background-color: white;
                    margin-block-start: 0;
                    max-height: 200px;
                    overflow: auto;
                    z-index: 20;

                    &_isContentExpanded {
                        display: block;
                    }
                }
            }

            &-DropDownList {
                margin: 0;
            }
        }
    }

    &-RangeWrapper {
        &_length_mm {
            grid-column: 1 / 3;
        }
    }
}
